<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Транскрибация аудио и видео файлов (до 10 гб), видео youtube vk, yandex-disk и google drive</h1>
        </div>
      </div>
    </div>

    <div class="container">


        <!--begin::Title-->
        <h3 class="text-bold my-2">
          Создать проект

        </h3>



      <div class="p-4">
        <form>


          <div class="row">
            <div class="col-3">
              <vs-upload automatic class="text-info"
                         :action="this.$hostnamejava + '/whisperx/run'"
                         fileName='file' :data="avatarForm" @on-success="successUpload()" text='Загрузить файл' />

            </div>

            <div class="col-9">

              <div class="row justify-content-start flex-column">
                <div class="form-group">
                  <label>Project name</label>
                  <div class="position-relative">
                    <message-circle-icon
                        class="fea icon-sm icons"
                    ></message-circle-icon>
                    <input
                        name="project_name"
                        id="project_name"
                        class="form-control pl-5"
                        placeholder="Название проекта"
                        v-model="input_api.project_name"
                    />
                  </div>

                </div>
              </div>
              <div class="row justify-content-start flex-column">
                <div class="form-group">
                  <label>Enforce language</label>
                  <div class="position-relative">
                    <message-circle-icon
                        class="fea icon-sm icons"
                    ></message-circle-icon>
                    <input
                        name="enforceTo"
                        id="enforceTo"
                        class="form-control pl-5"
                        placeholder="Остаьте пустым или введите язык 2 буквы: en,ru,fr,es,ch ... 38 языков"
                        v-model="input_api.enforceTo"
                    />
                  </div>

                </div>
              </div>

              <div class="row justify-content-start flex-column">
                <div class="form-group">
                  <label>Split audio after (in minutes)</label>
                  <div class="position-relative">
                    <message-circle-icon
                        class="fea icon-sm icons"
                    ></message-circle-icon>
                    <input
                        name="threshold"
                        id="threshold"
                        class="form-control pl-5"
                        placeholder="трэшхолд "
                        v-model="input_api.threshold"
                    />
                  </div>

                </div>
              </div>

              <div class="row justify-content-start flex-column">
            <div class="form-group">
              <label>VK Youtube URL</label>
              <div class="position-relative">
                <message-circle-icon
                    class="fea icon-sm icons"
                ></message-circle-icon>
                <input
                    name="keys"
                    id="keys"
                    class="form-control pl-5"
                    placeholder="https://youtu.be/3nfadIylKwc"
                    v-model="input_api.youtube"
                />
              </div>
              <a id="div-with-loading-youtube" @click="startTranscribeJob('youtube')" class="btn btn-outline-primary ml-1">
                Start VK YT</a>
            </div>
            </div>

              <div class="form-group">
                <label>Yandex disk</label>
                <div class="position-relative">
                  <message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <input
                      name="keys"
                      id="keys"
                      class="form-control pl-5"
                      placeholder="https://youtu.be/3nfadIylKwc"
                      v-model="input_api.yd"
                  />

                </div>
                <a id="div-with-loading-yd" @click="startTranscribeJob('yd')" class="btn btn-outline-primary ml-1">
                  Start YD</a>
              </div>
              <div class="form-group">
                <label>Yandex disk Folder of Folders</label>
                <div class="position-relative">
                  <message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <input
                      name="keys"
                      id="keys"
                      class="form-control pl-5"
                      placeholder="https://youtu.be/3nfadIylKwc"
                      v-model="input_api.yd_folder"
                  />

                </div>
                <a id="div-with-loading-ydfolder" @click="startTranscribeJob('ydfolder')" class="btn btn-outline-primary ml-1">
                  Start YD of folders</a>
              </div>
              <div class="form-group">
                <label>Google Drive Folder</label>
                <div class="position-relative">
                  <message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <input
                      name="keys"
                      id="keys"
                      class="form-control pl-5"
                      placeholder="https://youtu.be/3nfadIylKwc"
                      v-model="input_api.gd_folder"
                  />

                </div>
                <a id="div-with-loading-gdfolder" @click="startTranscribeJob('gdfolder')" class="btn btn-outline-primary ml-1">
                Start GD folder</a>
              </div>
              <div class="form-group">
                <label>Google Drive File</label>
                <div class="position-relative">
                  <message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <input
                      name="keys"
                      id="keys"
                      class="form-control pl-5"
                      placeholder="https://youtu.be/3nfadIylKwc"
                      v-model="input_api.gd_file"
                  />

                </div>
                <a id="div-with-loading-gdfile" @click="startTranscribeJob('gdfile')" class="btn btn-outline-primary ml-1">
                  Start GD file</a>
              </div>
          </div>

          </div>



        </form>

      </div>




    </div>




    </div>




</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";

export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "WhiperXAddPrject",
  data() {
    return {

      input_api: {
        user_id: 1,
        project_name:'Project',
        project_hash:'',
        threshold:'180',
        enforceTo:'',
        gd_folder:'',
        gd_file:'',
        yd:'',
        yd_folder:'',
        youtube:'',
      },
      tableShow: false,
      showStatus: false,
      status: {
        rows_in: 0, rows_out: 0,
      },
      token: '',
      excelResults: [],
      refreshTime: 1,

      audio2Text: [],

      input_meta: {
        project_name:'',
        threshold:'60',

      },
    }
  },


  computed: {
    avatarForm: function () {
      console.log('inside avatarForm');
      return {
        user_id: store.state.user_id,
        threshold: this.input_api.threshold,
        project_name: this.input_api.project_name,
        project_hash: this.$route.params.hash,
        traslate_flag: this.input_api.enforceTo,
      }
    },
  },

  methods: {

    successUpload() {
      this.$router.push({'path':'/whisperx'});
    },

    startTranscribeJob(x) {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$route.params.hash;
      let url = '' + this.$hostnamejava;
      let button_id = '#div-with-loading-'
      if (x==='youtube') {
        url = url + '/whisperx/run-youtube'
        button_id = button_id + 'youtube'
      } else if (x==='yd') {
        url = url + '/whisperx/run-yd'
        button_id = button_id + 'yd'
      } else if (x==='ydfolder') {
        url = url + '/whisperx/run-yd-folder'
        button_id = button_id + 'ydfolder'
      } else if (x==='gdfile') {
        url = url + '/whisperx/run-gd-file'
        button_id = button_id + 'gdfile'
      } else if (x==='gdfolder') {
        url = url + '/whisperx/run-gd-folder'
        button_id = button_id + 'gdfolder'
      }

      this.$vs.loading({
        container: button_id,
        scale: 0.5,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: url,
        data: this.input_api,
      }).then((res) => {
        console.log(res.data)
        if (res.data.result==='Done!') {
          //setTimeout(this.getStatus, 2000); // try again in 2000 milliseconds
          this.$vs.loading.close(button_id + " > .con-vs-loading");
          this.$router.push({ path: '/whisperx' });
        }
      });

    },








    },


}
</script>

<style scoped></style>